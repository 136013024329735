// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { isNil } from "lodash";
import { useIntl, IntlShape } from "react-intl";

import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";

enum DistanceUnit {
  Miles = "mi.",
  Kilometers = "km"
}

interface IDistanceProps {
  className?: string;
  distanceKm: number | null;
  variant?: Variant | "inherit";
}

const Distance = ({ className, distanceKm, variant }: IDistanceProps) => {
  const intl = useIntl();

  return !isNil(distanceKm) ? (
    <Typography className={className} data-qa="distance" variant={variant}>
      {DistanceService.format(distanceKm, intl)}
    </Typography>
  ) : null;
};

export default Distance;

export class DistanceService {
  public static format(distanceKm: number, intl: IntlShape) {
    const unit = this.getUnitByLocale(intl.locale);
    const distance = this.convertDistanceKmToUnit(distanceKm, unit);
    return intl.formatMessage(
      {
        id: "mobile.formatter.distance",
        defaultMessage: "{value} {unit}"
      },
      {
        value: intl.formatNumber(distance, {
          maximumFractionDigits: 2
        }),
        unit
      }
    );
  }

  private static getUnitByLocale(locale: string) {
    if (locale.toLocaleLowerCase().startsWith("en")) {
      return DistanceUnit.Miles;
    }

    return DistanceUnit.Kilometers;
  }

  private static convertDistanceKmToUnit(
    distanceKm: number,
    distanceUnit: DistanceUnit
  ) {
    return distanceUnit === DistanceUnit.Kilometers
              ? distanceKm
              : this.kmToMiles(distanceKm);
  }

  private static kmToMiles(distanceKm: number) {
    return distanceKm * 0.621371192;
  }
}
