// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FunctionComponent } from "react";
import { Action } from "redux";

import { IDrawerComponentProps } from "core/reducers/DrawerReducer";

import DrawerTypes from "../models/DrawerTypes";

export enum Actions {
  Drawer_OpenDrawer   = "Drawer_OpenDrawer",
  Drawer_CloseDrawer  = "Drawer_CloseDrawer",
  Drawer_ToggleDrawer = "Drawer_ToggleDrawer",
  Drawer_SetContent   = "Drawer_SetContent",
  Drawer_SetHeader    = "Drawer_SetHeader",
  Drawer_SetFooter    = "Drawer_SetFooter"
}

export interface IOpenDrawerAction extends Action<string> {
  drawerType: DrawerTypes;
}

export interface ICloseDrawerAction extends Action<string> {
  drawerType: DrawerTypes;
}

export interface IToggleDrawerAction extends Action<string> {
  drawerType: DrawerTypes;
}

export interface ISetDrawerContentAction extends Action<string> {
  drawerType: DrawerTypes;
  Content: FunctionComponent<IDrawerComponentProps>;
}

export interface ISetDrawerHeaderAction extends Action<string> {
  drawerType: DrawerTypes;
  Header: FunctionComponent<IDrawerComponentProps>;
}

export interface ISetDrawerFooterAction extends Action<string> {
  drawerType: DrawerTypes;
  Footer: FunctionComponent<IDrawerComponentProps>;
}

export const openDrawerAction = (
  drawerType: DrawerTypes
): IOpenDrawerAction => ({
  type: Actions.Drawer_OpenDrawer,
  drawerType
});

export const closeDrawerAction = (
  drawerType: DrawerTypes
): ICloseDrawerAction => ({
  type: Actions.Drawer_CloseDrawer,
  drawerType
});

export const toggleDrawerAction = (
  drawerType: DrawerTypes
): IToggleDrawerAction => ({
  type: Actions.Drawer_ToggleDrawer,
  drawerType
});

export const setDrawerContentAction = (
  drawerType: DrawerTypes,
  Content: FunctionComponent<IDrawerComponentProps>
): ISetDrawerContentAction => ({
  type: Actions.Drawer_SetContent,
  drawerType,
  Content
});

export const setDrawerHeaderAction = (
  drawerType: DrawerTypes,
  Header: FunctionComponent<IDrawerComponentProps>
): ISetDrawerHeaderAction => ({
  type: Actions.Drawer_SetHeader,
  drawerType,
  Header
});

export const setDrawerFooterAction = (
  drawerType: DrawerTypes,
  Footer: FunctionComponent<IDrawerComponentProps>
): ISetDrawerFooterAction => ({
  type: Actions.Drawer_SetFooter,
  drawerType,
  Footer
});
